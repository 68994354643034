<template>
  <div class="timHome">
    <div class="flex al-c user-wrap" @click="openUpdate">
      <img
        :src="
          userInfo.avatar || 'https://img.curiousmore.com/1669253666474.png'
        "
        alt=""
        class="user-avatar"
      />
      <div class="user-name">
        {{ userInfo.nick || "未命名" }}
      </div>
    </div>
    <div class="tim-wrap flex">
      <div class="left-box">
        <div v-if="timList.length == 0">
            暂无会话
        </div>
        <div
          class="flex conversation-item"
          v-for="(item, index) in timList"
          :key="index"
          :class="item.conversationID == IDlist.conversationID ? 'ac':''"
          @click="JumpChat(item)"
        >
          <div class="" style="position:relative;">
            <img class="left-img" :src="item.userProfile.avatar" alt="" />
            <div v-if="item.unreadCount">
              <div class="Unread" v-if="item.unreadCount <= 99">
                {{ item.unreadCount }}
              </div>
              <div class="Unread" v-else>99+</div>
            </div>
          </div>
          <div class="conversation-info">
            <p class="con-name">{{ item.userProfile.nick }}</p>
            <p
              class="con-msg"
            >
              {{ item.lastMessage.messageForShow }} 
            </p>
          </div>
        </div>
      </div>
      <div class="rihgt" style="flex: 1">
        <Chatpage
          v-if="IDlist.conversationID"
          :conversation="IDlist"
          ref="Chatpage"
        ></Chatpage>
        <el-empty :image-size="200" description="请选择用户会话"  v-if="!IDlist.conversationID"></el-empty>
        <!-- <MessagePage v-show="show == 0"></MessagePage> -->
      </div>
    </div>

    <el-dialog title="修改信息" :visible.sync="updateShow" width="30%">
      <el-form label-width="120px" ref="form">
         <el-form-item label="头像" >
          <avatar-uploader
            ref="imgs"
            @getImgUrl="(v) => {this.form.avatar = v}"
            :url="userInfo.avatar"
          >
          </avatar-uploader>
        </el-form-item>
        <el-form-item label="昵称" >
          <el-input v-model="form.nick" placeholder="昵称"></el-input>
        </el-form-item>
       
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateShow = false">取 消</el-button>
        <el-button type="primary" @click="updateUserInfo"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Chatpage from "./components/Chatpage.vue";
import MessagePage from "./components/MessagePage.vue";
export default {
  components: {
    Chatpage,
    MessagePage,
  },
  data() {
    return {
      form:{nick:'',avatar:''},
      userInfo: {},
      timList: [],
      IDlist: {},
      userName: {},
      userList: [],
      show: 0,
      updateShow:false,
      managerInfo : JSON.parse(localStorage.getItem('managerInfo'))
    };
  },
  computed: {
     timLogin() {
      return this.$store.state.timLogin;
    },
  },
  mounted() {
    if(this.timLogin){
      this.handleSDKReady()
    }else{
      this.$store.dispatch('loginTim')
    }
     
      
      this.$tim.on(this.$tims.EVENT.SDK_READY, this.handleSDKReady);
      this.$tim.on(
        this.$tims.EVENT.CONVERSATION_LIST_UPDATED,
        this.onConversationListUpdated
      );
     
  },
  destroyed() {
     sessionStorage.setItem("user", JSON.stringify(this.userInfo));
    
    this.$tim.off(this.$tims.EVENT.SDK_READY, this.handleSDKReady);
    this.$tim.off(
      this.$tims.EVENT.CONVERSATION_LIST_UPDATED,
      this.onConversationListUpdated
    );
  },
  methods: {
    openUpdate(){
        this.form.nick = this.userInfo.nick
         this.form.avatar = this.userInfo.avatar
         this.updateShow = true
    },
    navigateChatUser() {
      if (this.$route.params.user) {
        this.$tim
          .getConversationProfile("C2C" + this.$route.params.user)
          .then((res) => {
            const { conversation } = res.data;
            this.show = 1;
            this.IDlist = conversation;
            this.userName = conversation;
          });
      }
    },
    //获取自己的消息
    getUserInfo() {
      this.$tim.getMyProfile().then((res) => {
        this.userInfo = res.data;
      });
    },
    updateUserInfo() {
				this.$tim.updateMyProfile({
					nick: this.form.nick,
					avatar: this.form.avatar,
				}).then(res => {
            this.updateShow = false
            this.userInfo.nick = this.form.nick
            this.userInfo.avatar = this.form.avatar
				})
			},
    // 挂载完毕
    handleSDKReady() {
      this.getUserInfo();
      this.getConversationList();
      this.navigateChatUser();
      // let TM = setTimeout(() => {
      //     this.TimUser()
      //     clearTimeout(TM)
      // }, 1000)
    },
    getConversationList() {
      this.$tim.getConversationList().then((imResponse) => {
        if (imResponse.data.data.conversationList.length > 0) {
           this.timList  = imResponse.data.data.conversationList;
        }
      });
    },
    TimUser() {
      if (this.$store.state.TimUser_id) {
        let id = "C2C" + this.$store.state.TimUser_id;
        let list = this.timList.filter((element) => {
          return id == element.conversationID;
        });
        if (list.length) {
          this.show = 1;
          this.IDlist = list[0];
          this.userName = list[0];
          this.$store.commit("SET_TimUser_id", "");
        } else {
          this.$refs.Chatpage.$refs.SendOut.greetsb();
        }
      }
    },

    JumpChat(e) {
      this.show = 1;
      this.IDlist = e;
      this.userName = e;
    },
    

    // 获取列表
    onConversationListUpdated(event) {
      this.timList = event.data;
  
    },
  },
};
</script>

<style lang="less" scoped>
.tim-wrap{
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.conversation-item{
  padding: 10px;
  cursor: pointer;
  &:hover{
    background-color: #fc781905;
  }
}

.ac{
  background-color:#fc781910;
}

.left-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 5px;
  position: relative;

  
}
.Unread {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    background-color: #e84a34;
    color: #f0f0f0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
  }
.conversation-info{
  border-bottom: 1px solid #f4f4f4;
  box-sizing: border-box;
  padding: 5px 0 0 10px;
  .con-name{
    width: 200px;
      font-size: 14px;
       overflow: hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
      font-weight: 600;
  }
  .con-msg{
    width: 200px;
      font-size: 13px;
      color: #575656;
      overflow: hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
      margin-top: 5px;
  }
}

.user-wrap {
  padding: 5px 16px;
  border-radius: 25px;
  width: fit-content;
  background-color: #fc781910;
  margin-bottom: 10px;
  cursor: pointer;
  .user-name {
    font-size: 15px;
   
  }
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
     margin-top: 6px ;
  }
}

.left-box {
  width: 300px;
  border-right: 1px #f0f0f0 solid;

  .left-right {
    border-bottom: 1px #f0f0f0 solid;
    padding: 10px 0;
    width: 100%;
  }
}

.timHome {
  width: 90%;
    height: calc(100vh - 120px);
  // background-color: #ffffff;
  margin-left: 20px;
}
</style>